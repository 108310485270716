<template>
    <my-layout class="about_us">
        <template #body>
            <div class="header">
                <img @click="$router.push('/h5')" src="../../assets/img/h5/logo_h5.png"/>
                <div class="right">
                    <span>关于</span>
                    <div class="button" @click="handleLogin">登录/注册</div>
                </div>
            </div>
            <div class="about_body">
                <div class="claim" @click="$router.push('/claim_h5')"></div>
                <a class="link" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"></a>
            </div>
        </template>
    </my-layout>
</template>

<script>
  import { get_user_info } from '@/api/common'
  export default {
    name: "aubotUs",
    methods: {
      handleLogin() {
        get_user_info().then(res => {
          if(res.data.result_code == 0){
            this.$router.push('/box/dataView');
          }
        })
      },
    }
  }
</script>

<style scoped lang="scss">
.about_us{
    width: 100%;
    overflow: scroll;
    .header {
        width: 100%;
        height: 170px;
        background-color: #ffffff;
        color: #1f81f8;
        font-size: 42px;
        display: flex;
        align-items: center;
        position: relative;
        img  {
            width: 377px;
            height: 111px;
            position: absolute;
            left: 58px;
        }
        .right {
            position: absolute;
            right: 58px;
            .button {
                width: 240px;
                height: 90px;
                background-color: #ffffff;
                border: solid 3px #1f81f8;
                border-radius: 50px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-left: 76px;
                font-size: 36px;
                &:active {
                    background-color: #f6f9fe;
                }
            }
        }

    }
    .about_body {
        width: 100%;
        height: 2160px;
        background: url("../../assets/img/h5/about.png");
        background-size: cover;
        position: relative;
        .claim {
            position: absolute;
            bottom: 410px;
            left: 60px;
            width: 300px;
            height: 50px;
            display: inline-block;
        }
        .link {
            width: 50%;
            height: 100px;
            opacity: 0;
            position: absolute;
            left: 25%;
            bottom: 5px;
        }
    }
}
</style>
